let tabBlocks = document.querySelectorAll("._tabs-parent")

tabBlocks.forEach(tabBlock => {
    let tabs = tabBlock.querySelectorAll("._tab")
    let tabContents = tabBlock.querySelectorAll("._tab-content")

    tabs.forEach(tab => {
        tab.onclick = function(){
            let tabId = this.dataset.tab

            tabs.forEach(tab => {
                tab.classList.remove("_active")
            })
            tab.classList.add("_active")

            tabContents.forEach(tabContent => {
                tabContent.classList.remove("_active")
            })
            tabBlock.querySelector(`._tab-content.${tabId}`).classList.add("_active")
        }

    })
})


$(".mob-detail__show").click(function () {
    $(this).parents(".mob-detail").toggleClass("_active")
    $(this).siblings(".mob-detail__hidden").slideToggle()
})

$("._show-all-colors").click(function () {
    $(".colors").addClass("_all-colors")
})

$(".color-field input").change(function () {
    setColor($(this))
})

setColor($(".color-field input:checked"))

function setColor(el) {
    let title = $(el).parents(".color-field").find("._color-title").text().replace(/\s+/g, " ")
    $("._selected-color").html(title)
}

function cartCalc(){
    $('.cartcalc .ccalc-minus').click(function(){
        let a = $(this).closest('.cartcalc').find('input').val();
        if(a>1){
            let b = +a-1;
            $(this).closest('.cartcalc').find('input').val(b);
        }else{
            $(this).closest('.cartcalc').find('input').val(a);
            $(".cart-nav").removeClass("_added")
        }
    });
    $('.cartcalc .ccalc-plus').click(function(){
        let a = $(this).closest('.cartcalc').find('input').val();
        let b = +a+1;
        $(this).closest('.cartcalc').find('input').val(b);
    });
}
cartCalc()

$("._add-to-cart").click(function () {
    $(".cart-nav").addClass("_added")
})

fixCartNav()
$(window).scroll(function() {
    fixCartNav()
})

function fixCartNav() {
    if ($(window).scrollTop() > 800) {
        $(".buy-section").addClass("_show")
    } else {
        $(".buy-section").removeClass("_show")
    }
}

$("._all-props").click(function () {
    $(".catalog-detail").find("[data-tab='_tab2']").click()
})
