function gallery() {
    var swiper = new Swiper(".gallery .mySwiper", {
        spaceBetween: 0,
        slidesPerView: "auto",
        freeMode: true,
        watchSlidesProgress: true,
    });
    var swiper2 = new Swiper(".gallery .mySwiper2", {
        spaceBetween: 0,
        slidesPerView: "auto",
        thumbs: {
            swiper: swiper,
        },
        navigation: {
            nextEl: '.gallery .swiper-button-next',
            prevEl: '.gallery .swiper-button-prev',
        },
        pagination: {
            el: '.gallery .swiper-pagination',
            type: 'bullets',
            clickable: true,
        },
    });
}

gallery()
