window.addEventListener('DOMContentLoaded', () => {
    $(".m-select select").niceSelect()

    fix100vh();
    getScrollBarSize()
    window.addEventListener('resize', () => {
        fix100vh();
        getScrollBarSize()
    })


})

function fix100vh() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
}
