$(".filter-group__toggle").click(function () {
    let parent = $(this).parents(".filter-group")

    $(parent).toggleClass("_active")
    console.log($(parent).find(".filter-group__bottom"))
    $(parent).find(".filter-group__bottom").slideToggle()
})

$(".filter-group__more").click(function () {
    $(this).siblings(".filter-group__checkboxes").addClass("_show-all")
})


let filterSliders = document.querySelectorAll(".filter-slider")

filterSliders.forEach(slider => {
    const rangeSlider = slider.querySelector(`.range-slider`)
    const rangeInput0 = slider.querySelector(`.range-slider__input1`)
    const rangeInput1 = slider.querySelector(`.range-slider__input2`)
    const rangeInputs = [rangeInput0, rangeInput1]

    let startValue1 = +slider.getAttribute("data-start")
    let startValue2 = +slider.getAttribute("data-start2")
    let min = +slider.getAttribute("data-min")
    let max = +slider.getAttribute("data-max")

    if (rangeSlider) {
        noUiSlider.create(rangeSlider, {
            start: [startValue1, startValue2],
            connect: true,
            step: 1,
            range: {
                'min': min,
                'max': max
            },
            pips: {mode: 'count', values: 5}
        });
    }
    if (rangeSlider) {
        rangeSlider.noUiSlider.on('update', function(values, handle) {
            rangeInputs[handle].value = Math.round(values[handle])
        })
        rangeInputs.forEach((el, index) => {
            el.addEventListener('change', (e) => {
                setRangeSlider(index, e.currentTarget.value)
            })
        })
    }
    const setRangeSlider = (i, value) => {
        let array = [null, null];
        array[i] = value;

        rangeSlider.noUiSlider.set(array);
    };
})


$(".mob-filter__btn").click(function () {
    $(".filter").toggleClass("_active")
    $("body").toggleClass("fixed-body")
})

$(".filter__close").click(function () {
    $(".filter").toggleClass("_active")
    $("body").toggleClass("fixed-body")
})


$(".cart-info__close").click(function () {
    $(".cart-info").removeClass("_active")
})

$("._add-to-cart").click(function () {
    $(this).toggleClass("_active")
})

